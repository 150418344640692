<template>
  <div class="header">
    <!-- <div class="logo-wrap">
      <a @click="$router.push({ name: 'Home' }).catch(() => {})">
        <v-img class="logo" src=""></v-img>
      </a>
    </div> -->

    <div class="select-wrap">
      <v-select
        hide-details
        class="select"
        v-model="lang"
        :items="languages"
        item-text="title"
        item-value="language"
        @change="changeLocale"
        outlined
        dense
      ></v-select>
    </div>
    <div class="menu-wrap">
      <v-btn class="menu-item" text :to="{ name: 'Home' }">
        {{ $t("0001") }}
      </v-btn>

      <v-btn class="menu-item" text :to="{ name: 'AboutUs' }">
        {{ $t("0002") }}
      </v-btn>

      <v-btn class="menu-item" text :to="{ name: 'Career' }">
        {{ $t("0004") }}
      </v-btn>

      <v-btn class="menu-item" text :to="{ name: 'SupplierResponsibility' }">
        {{ $t("0163") }}
      </v-btn>

      <v-btn class="menu-item" text :to="{ name: 'SafetyHandbbok' }">
        {{ $t("0214") }}
      </v-btn>
      <v-btn class="menu-item" text :to="{ name: 'Procurement' }">
        {{ $t("0233") }}
      </v-btn>

            <v-btn class="menu-item" text :to="{ name: 'ContactUs' }">
        {{ $t("0003") }}
      </v-btn>
      <!-- <v-btn class="menu-item" text :to="{ name: 'BusinessContact' }">
        {{ $t("0146") }}
      </v-btn> -->


    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
export default {
  data() {
    return {
      hideNav: false,
      lang: "en",
      languages: [
        { flag: "us", language: "en", title: "EN" },
        { flag: "th", language: "th", title: "TH" },
      ],
      menuItems: [
        { title: "Home", path: "/home" },
        { title: "About us", path: "/about-us" },
        { title: "Contact Us", path: "/contact-us" },
        { title: "Career ", path: "/career" },
        { title: "Supplier Responsibility", path: "/supplier-responsibility" },
        { title: "Safety Handbbok", path: "/safety-handbbok" },
        // { title: "Procurement", path: "/safety-handbbok" },
        // { title: "Business Contact ", path: "/business-contact" },
      ],
    };
  },

  mounted() {
    i18n.locale = this.lang;
    localStorage.setItem('languages', this.lang)
  },
  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      this.$emit('changeLocale', locale)
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.375rem;
  font-family: SAMSUNGSHARPSANSBOLD;
  letter-spacing: 2px;
  position: absolute;
  top: 30px;
  left: 30px;
}
.header {
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  animation: slidein;
  animation-duration: 2s;
  height: 85px;
  backdrop-filter: blur(10px);
  background-color: #ffffffa8;
}
.logo-wrap {
  position: relative;
}
.logo {
  width: 139px;
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 5;
}
.menu-wrap {
  position: relative;
  padding-top: 25px;
  z-index: 4;
}
.menu-item {
  background-color: transparent;
  margin: 0 10px;
  padding: 0 6px !important;
  letter-spacing: 1.2px !important;
}
.menu-item.v-btn--active::before{
  background-color: transparent;
  border-bottom: 2px solid #349fc7;
  opacity: 1;
  border-radius: unset;
}
.menu-item:hover{
  opacity: 1;
  border-radius: unset;
}
.v-btn:before{
  background-color: transparent;
}
.select-wrap {
  position: relative;
  z-index: 5;
}
.select {
  position: absolute;
  top: 22px;
  right: 30px;
  width: 80px;
}
@media screen and (max-width: 1500px) {
  .header {
    height: 80px;
  }
  .logo {
    width: 130px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
  .menu-wrap {
    display: none;
  }
  .select-wrap {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .logo {
    width: 80px;
    position: absolute;
    top: 25px;
  }
  .header {
    height: 60px;
    backdrop-filter: unset;
    background-color: #ffffffde;
  }
}
</style>
